import {useState, useEffect} from 'react';

import callAPI from '../../services/callAPI';

import {useNavigate}  from 'react-router-dom';

import {sessionSet} from '../../services/session'

import { useParams } from 'react-router-dom';

import SpinnerQuantoVale from '../../components/shared/Spinner';
import LayoutAnonymous from '../../components/shared/LayoutAnonymous';

import {Container, Row} from 'react-bootstrap'
import { useCookies } from 'react-cookie';

export function LoginRouter() 
{  
  const [cookies, setCookie] = useCookies(['temporary_token']);

  const {category} = useParams();
 
  const navigate = useNavigate();
  

  useEffect( () => { 

    setTimeout(()=>{

      
      signin(cookies.temporary_token, category)
    }, 1000)

    
  }, []);


  const signin = async (tk: string | undefined, ct: string | undefined) =>{

      const result = await callAPI({service: '/Auth/token', dados: {Secret: tk}})
  
      if(result == null)              
      {       
        return;
      }
  
      if(result)
      {      
       
        if (result.token) {
          sessionSet(result);
        }
       
        setTimeout(()=>{          
            navigate("/pesquisas");
        }, 3000)
      }
    
  }

  
  return (<>
        <LayoutAnonymous>
          <Container className='loaderQuantoVale mt-5'>
              <Row>
                <SpinnerQuantoVale active={true}/>
              </Row>
          </Container>
        </LayoutAnonymous>
   </>);

}