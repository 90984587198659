
const sessionGet = () =>
{    
  const auth = localStorage.getItem("auth");
  if (auth) {
      return JSON.parse(auth);
  }
  return null;
}

const sessionSet = (data:any) =>
{    
  localStorage.setItem("auth", JSON.stringify(data));  
}

export {sessionGet, sessionSet};