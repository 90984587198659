
import {AppRoutes} from "./AppRoutes"

function App() {  
  return (
   <AppRoutes/>
  )
}

export default App
