
import React, { FC, ReactNode } from "react";

import {Row, Col} from 'react-bootstrap'

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/globals.css'
import '../../styles/custom.css'

import 'react-confirm-alert/src/react-confirm-alert.css';



import Footer from './Footer'
import HeaderAuth from './HeaderAuth'


// define interface to represent component props
interface Props {
  novo?: boolean;
  children?: ReactNode;
}

const LayoutAuth: FC<Props> = ({ children }) => {

  

 
  
  

  return (    
    <>
   
    <HeaderAuth />  
    
    <Row><Col className="col-12"><ToastContainer /></Col></Row>

  
    { children }
  
    <Footer />

   

  </>
);
}

export default LayoutAuth;