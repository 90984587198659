import React, {useState, useEffect} from 'react';


import callAPI from '../../services/callAPI';
import { Button, Card, Col, Container, Row, Table } from 'react-bootstrap';
import Zoom from 'react-medium-image-zoom'


import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faLink, faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons';
import ModalGeneric from '../../components/shared/ModalGeneric';
import { FormVehicleMarket } from './FormVehicleMarket';

type Props = {    
     
    VehicleId: number;
   
    handleClose: () => void; 
   
    
}

export function FormVehicleDetail({VehicleId, handleClose}:Props) 
{
   
     

    
    const [Name, setName] = useState('');
    const [Source, setSource] = useState('');
    const [SourceUrl, setSourceUrl] = useState('');
    const [Brand, setBrand] = useState('');
    const [Model, setModel] = useState('');
    const [Description, setDescription] = useState('');
    const [Year, setYear] = useState(0);
    const [YearModel, setYearModel] = useState(0);
    const [City, setCity] = useState('');
    const [UF, setUF] = useState('');
    const [VehicleTransmission, setVehicleTransmission] = useState('');
    const [FuelType, setFuelType] = useState('');
    const [MileageFromOdometer, setMileageFromOdometer] = useState(0);
    const [Price, setPrice] = useState(0);
    const [Photo, setPhoto] = useState('');
    const [PhotoMain, setPhotoMain] = useState('');
    const [Tipo, setTipo] = useState('');
    const [Url, setUrl] = useState('');
    const [Color, setColor] = useState('');
    const [Bodywork, setBodywork] = useState('');
    const [Plate, setPlate] = useState('');
    const [PaymentConditions, setPaymentConditions] = useState('');
    const [Acessories, setAcessories] = useState('');
    const [Images, setImages] = useState('');
    const [ImagesArray, setImagesArray] = useState<string[]>([]);

    const [LoadedImages, setLoadedImages] = useState(false);
    const [Loaded, setLoaded] = useState(false);

    const [showModalMarket, setShowModalMarket] = useState(false);

    //FIPE
    const [FipeMarca, setFipeMarca] = useState('');
    const [FipeModelo, setFipeModelo] = useState('');
    const [FipeCodigoFipe, setFipeCodigoFipe] = useState('');
    const [FipeMesReferencia, setFipeMesReferencia] = useState('');
    const [FipeValor, setFipeValor] = useState(0);
    
    useEffect(() => {
        if(Images)
        {        
            const arrayImagens = Images.split('|');
            setImagesArray(arrayImagens);
            
            
            
        }
      }, [Images]);

    useEffect(() => {
        if(ImagesArray.length > 0)
        {
            console.log('ImagesArray',ImagesArray)
            setLoadedImages(true)
            
            
        }


    }, ImagesArray)

    useEffect( () => { 

        if(VehicleId)
            getVehicle()

    }, [VehicleId]);
    

    const getVehicle = async () => {       
        
                
        const result = await callAPI({service: '/Vehicle/getVehicle', dados: {Id: VehicleId}})        
        if(result == null)
          return;
        console.log(result)
        if(result.success)
        {            
            
            
            setName(result.vehicle.name);
            setSource(result.vehicle.sources.nome);
            setSourceUrl(result.vehicle.sources.url);
            setBrand(result.vehicle.brand);
            setModel(result.vehicle.model);
            setDescription(result.vehicle.description);
            setYear(result.vehicle.year);
            setYearModel(result.vehicle.yearModel);
            setCity(result.vehicle.city);
            setUF(result.vehicle.uf);
            setVehicleTransmission(result.vehicle.vehicleTransmission);
            setFuelType(result.vehicle.fuelType);
            setMileageFromOdometer(result.vehicle.mileageFromOdometer);
            setPrice(result.vehicle.price);
            setPhoto(result.vehicle.photo);
            setPhotoMain(result.vehicle.photo);
            setTipo(result.vehicle.tipo);
            setUrl(result.vehicle.url);
            setColor(result.vehicle.color);
            setBodywork(result.vehicle.bodywork);
            setPlate(result.vehicle.plate);
            setPaymentConditions(result.vehicle.PaymentConditions);
            setAcessories(result.vehicle.acessories);            
            setImages(result.vehicle.images);
            

        
            //FIPE
            if(result.vehicle.fipeVeiculoValor)
            {
                setFipeMarca(result.vehicle.fipeVeiculoValor.marca);
                setFipeModelo(result.vehicle.fipeVeiculoValor.modelo);
                setFipeCodigoFipe(result.vehicle.fipeVeiculoValor.codigoFipe);
                setFipeMesReferencia(result.vehicle.fipeVeiculoValor.mesReferencia);
                setFipeValor(result.vehicle.fipeVeiculoValor.valor); 
            }
    
            
            
            

            setLoaded(true)

            
        }
        
    }

    if(!Loaded)return(<>aguarde...</>)

    return (<>
            {Name != '' && <>
            <Container className='mt-2'>
                <Row>
                    <Col className='col-6'>
                    {LoadedImages && ImagesArray.length > 0 &&<>
                        <Row>
                            <Col className='col-12'>                            
                            <>{ImagesArray.map((data: string, key:number) => {

                                <a onClick={() => {setPhotoMain(data)}} href="#"><img width={40} height={40} src={data} referrerPolicy='no-referrer'/></a>

                            })}</>
                            </Col>
                        </Row>             
                        </>}      
                        <Row>
                            <Col className='col-12'>
                                <Card>
                                    <Card.Header>{Brand.toUpperCase()+'/'+ Model+' - ' + Year +'/'+YearModel}</Card.Header>
                                    <Card.Body className='text-center'>
                                        <Row>
                                            <Col className='col-12'>
                                            <Zoom  zoomMargin={115}><img height="306" src={PhotoMain} alt={Model} referrerPolicy='no-referrer'/></Zoom>
                                            </Col>
                                        </Row>
                                    </Card.Body>                            
                                </Card>
                            </Col>                        
                        </Row>
                        
                        <Row>
                            <Col className='col-12 text-center mt-3'>
                                <Button type="button" className='btn-width' onClick={(e: any) => { e.preventDefault(); window.open(Url, '_blank');}} variant="secondary">
                                    <FontAwesomeIcon icon={faLink} />&nbsp;Visitar Anúncio
                                </Button>                                
                               <Button type="button" className='btn-width' onClick={(e: any) => { e.preventDefault(); setShowModalMarket(true) }} variant="secondary">
                                    <FontAwesomeIcon icon={faMagnifyingGlassChart} />&nbsp;Análise de Mercado
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='col-6'>
                        <Card>
                            <Card.Header className='text-end detail-number'>{<NumberFormat fixedDecimalScale={true} decimalScale={2} value={Price} displayType={'text'} thousandSeparator={"."}  decimalSeparator={","} prefix={"R$ "} />}</Card.Header>
                            <Card.Body>
                                <Table striped bordered hover>
                                    <tbody>
                                        <tr className='detail-tr'>
                                            <td>Vendido por:</td>
                                            <td>{<a href={SourceUrl}>{Source}</a>}</td>
                                        </tr>
                                        <tr className='detail-tr'>
                                            <td>Descrição:</td>
                                            <td>{Description}<br/>{PaymentConditions}</td>
                                        </tr>
                                        <tr className='detail-tr'>
                                            <td>Transmissão:</td>
                                            <td>{VehicleTransmission}</td>                                            
                                        </tr>
                                        <tr className='detail-tr'>
                                            <td>KM:</td>
                                            <td>{<NumberFormat fixedDecimalScale={false} value={MileageFromOdometer} displayType={'text'} thousandSeparator={"."}  decimalSeparator={","} prefix={""} />}</td>                                            
                                        </tr>
                                        <tr className='detail-tr'>
                                            <td>Combustível:</td>
                                            <td>{FuelType}</td>                                            
                                        </tr>
                                        <tr className='detail-tr'>
                                            <td>Cidade/UF:</td>
                                            <td>{City+'/'+UF}</td>                                            
                                        </tr>
                                        {Color && <>
                                            <tr className='detail-tr'>
                                                <td>Cor:</td>
                                                <td>{Color}</td>                                            
                                            </tr>
                                        </>}
                                        {Bodywork && <>
                                            <tr className='detail-tr'>
                                                <td>Carroceria:</td>
                                                <td>{Bodywork}</td>                                            
                                            </tr>
                                        </>}
                                        {Plate && <>
                                            <tr className='detail-tr'>
                                                <td>Placa:</td>
                                                <td>{Plate}</td>                                            
                                            </tr>
                                        </>}
                                        {Acessories && <>
                                            <tr className='detail-tr'>
                                                <td>Acessórios:</td>
                                                <td>{Acessories}</td>
                                            </tr>
                                        </>}
                                    </tbody>
                                </Table>                                
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> 
                {FipeCodigoFipe != '' &&<>
                    <Row className='mt-2'>
                        <Col className='col-12'>
                            <Card>
                                <Card.Header>Dados FIPE</Card.Header>
                                <Card.Body className='text-center'>
                                    <Table striped bordered hover>
                                        <tbody>
                                            <tr className='detail-tr-fipe'>
                                                <td>Código FIPE:</td>
                                                <td>{FipeCodigoFipe}</td>                                            
                                                <td>Marca/Modelo:</td>
                                                <td>{FipeMarca+'/'+FipeModelo}</td>                                            
                                            </tr>
                                            <tr>
                                                <td>Mês Referência:</td>
                                                <td>{FipeMesReferencia}</td>                                                                                        
                                                <td>Valor:</td>
                                                <td><b>{<NumberFormat fixedDecimalScale={true} value={FipeValor} displayType={'text'} thousandSeparator={"."}  decimalSeparator={","} prefix={"R$ "} />}</b></td>                                            
                                            </tr>                                            
                                        </tbody>
                                    </Table>  
                                
                                </Card.Body>                            
                            </Card>
                        </Col>
                    </Row>
                </>}
                 <Row className='mt-4'>
                    <Col className='col-12 col-md-12 text-end btn-actions-bottom'>
    
                        <a onClick={(e: any) => { e.preventDefault(); handleClose() }} className="btn btn-width btn-primary text-light">
                        <FontAwesomeIcon icon={faClose} />&nbsp;Fechar
                        </a>                                        
    
                    </Col>
                </Row>                
            </Container>
            </>}   
            <ModalGeneric noFooter={true} icon={faMagnifyingGlassChart} Size={"xl"} Title={"Análise de Mercado"} active={showModalMarket} handleClose={()=>{setShowModalMarket(false); }}>
                <FormVehicleMarket VehicleId={VehicleId} handleClose={()=>{setShowModalMarket(false);}}/>
            </ModalGeneric>      
        
          
    </>  
        
 );
}