import {useState, useEffect, useCallback, useRef} from 'react';



import PageTitle from "../../components/shared/PageTitle"

import {Card, Button, Form, Container, Row, Col} from 'react-bootstrap'

import {errorMessage, successMessage, infoMessage, defaultMessage} from '../../services/message';

import callAPI from '../../services/callAPI';

import {useNavigate, useParams}  from 'react-router-dom';

import {sessionSet} from '../../services/session'


import { GoogleLoginGeneric } from '../../components/auth/GoogleLoginGeneric';

import { jwtDecode } from 'jwt-decode';

import { useCookies } from 'react-cookie';
import LayoutAnonymous from '../../components/shared/LayoutAnonymous';



import { authUrlAny, authUrlDefault, authUrlLogin } from '../../services/auth';
import CaptchaGeneric from '../../components/shared/CaptchaGeneric';


export function Forgot() 
{  


  const navigate = useNavigate();
  let [Username, setUsername] = useState('')
  let [Password, setPassword] = useState('')
  let [Remember, setRemember] = useState('0')

  let [Sub, setSub] = useState('')
  let [AuthType, setAuthType] = useState(1)

  const {subdominio} = useParams();

  const [captchatoken, setCaptchaToken] = useState<null | string>(null);

  useEffect( () => { 
      
      if(!subdominio)
        window.location.replace(authUrlDefault());  
        
    
  
    
  }, []);
  
  


  const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

   forgot(Username)
  }

  const forgot = async (username: string) =>{

      if(!captchatoken)
      {
        errorMessage("Você é um Robô?")
        return
      }

      const result = await callAPI({service: '/Auth/forgot', dados: {Username: username, Subdominio: subdominio}})
  
      if(result == null)
      return;
  
      if(result)
      {      
        setUsername('');
        successMessage(result.message)
      }
    
  }

  const onCaptchaChange = (value: any) =>{
    if(value)
    {
      setCaptchaToken(value);
    }
    else
      setCaptchaToken(null);
  }

  
  return (<><title>QuantoVale - Recuperação de Senha</title>

    <LayoutAnonymous >
        <Container className="mt-5 container-login">
          <Row>
          <Col className='col-xl-4 offset-xl-4 col-lg-6 col-md-10 offset-md-1 offset-lg-3 mb-1'>
            <Card className="mb-3">
              <Card.Body className="card-body">
                <Card.Title>Recuperação de Senha</Card.Title>                                             
                <Form onSubmit={(e) => handleSubmit(e)}>                             
                <Form.Group className="mt-3 mb-2">                                
                    <Form.Control 
                    type="text"
                    placeholder="Email, CPF ou Celular"
                    required
                    value={Username}
                    onChange={e => setUsername( e.target.value )}
                  />
                  </Form.Group>   
                  <Row className='mt-2'><Col></Col><Col>
                  <CaptchaGeneric onChange={onCaptchaChange}/>
                  </Col><Col></Col></Row>
                  <Row className="mt-2 d-grid">
                     <Button type="submit" disabled={captchatoken?false:true}  className="btn btn-primary btn-lg" variant="primary">
                      Enviar
                    </Button>
                    <Button type="button"  onClick={(e) => {e.preventDefault();navigate("/login/"+subdominio)}} className="mt-1 btn btn-secondary btn-lg"   variant="secondary">
                      Cancelar
                    </Button>
                  </Row>
                </Form>
              </Card.Body>
            </Card></Col>
         
          </Row>
        
      </Container>                   
      
    </LayoutAnonymous>  
 

</>
  );

}