import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ContentLoader from 'react-content-loader'

type Props = {
    active: boolean;
}

function SpinnerQuantoVale({active}:Props) {
    
    return (
       <>
            {active &&            
            <ContentLoader title='Aguarde...'/>
            }
       </>
       
    );
}

export default SpinnerQuantoVale;