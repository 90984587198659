import React, {useState, useEffect} from 'react';

import PageTitle from "../../components/shared/PageTitle"

import {Table,Container, Row, Col} from 'react-bootstrap'


import callAPI from '../../services/callAPI';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'


import { useCookies } from 'react-cookie';
import { authUrl401 } from '../../services/auth';



import LayoutAuth from '../../components/shared/LayoutAuth';
import ModalGeneric from '../../components/shared/ModalGeneric';
import { FormBuscaAvancada } from './FormBuscaAvancada';
import { FormVehicleDetail } from './FormVehicleDetail';

import 'react-medium-image-zoom/dist/styles.css'
import Zoom from 'react-medium-image-zoom'


import NumberFormat from 'react-number-format';


export function Main() 
{
    const [cookie, setCookie] = useCookies(['ActualState', 'FlashError']);
    
    const [dados, setDados] = useState([]);    
    const [loaded, setLoaded] = useState(false);
    const [search, setsearch] = useState('');

    const [vehicleId, setVehicleId] = useState(0)

    //Busca avançada
    const [Tipos, setTipos] = useState([]);    
    const [Ufs, setUfs] = useState([]);

    const  [ShowModalAdvanced, setShowModalAdvanced] = useState(false)
    const  [ShowModalDetail, setShowModalDetail] = useState(false)
    



    const [Pagination, setPagination] = useState(false);
    const [LimitPerPage] = useState(8);
    const [Pages, setPages] = useState(new Array<string>());
    const [ActualPage, setActualPage] = useState(1);    
    const [NumberOfPages, setNumberOfPages] = useState(1);

    
    


    useEffect( () => { 

        if(search != '' && search.length > 2)
        {
            getDados()                  
        }
        else{
            setLoaded(false)
        }

    }, [search]);

    useEffect( () => { 

        if(dados.length > LimitPerPage+1)
        {
            setActualPage(1)
            setPagination(true)

            const ar = []
                
            for(let i = 1; i <= Math.round(dados.length / LimitPerPage)+(((((dados.length ) * 100) % LimitPerPage) / 100)>=0.05?1:0); i++)
                ar.push(""+i);

            setPages(ar);
            setNumberOfPages(ar.length);
        }
        else
        {
            setPagination(false)
        }                  

    }, [dados]);


    useEffect( () => { 

        if(ShowModalAdvanced)
        {
            openBuscaAvancada()            
        
        }

    }, [ShowModalAdvanced]);

    const openBuscaAvancada = async () => {        
        if(Tipos.length == 0) advancedsearchLoad()
    }

   

    const getDados = async () => {
        
        setDados([])                                       
        setLoaded(false)
        const result = await callAPI({service: '/Vehicle/search', dados: {query: search}})
          
        
        if(result == null)
        {      
            
          return;
        }

        if(result == 401)
        {
              setCookie("FlashError", "Operação não permitida!", { path: '/' });
            
              setTimeout(()=>{
                window.location.replace(authUrl401());  
              }, 1000)
        }
    
        if(result)
        {                       
            setDados(result.vehicles)                                       
            
                  
        }
        setLoaded(true);             
        
    }

    const searchAdvanced = async(marcaId: number, modeloId: number, anoId:number, UF: string, cidade: string) =>{
        setLoaded(false)
        const result = await callAPI({service: '/Vehicle/searchAdvanced', dados: {
            marcaId: marcaId, modeloId: modeloId, anoId: anoId, uf: UF, cidade:cidade
        }})
          
        if(result == null)
        {      
            
          return;
        }

        if(result == 401)
        {
              setCookie("FlashError", "Operação não permitida!", { path: '/' });
            
              setTimeout(()=>{
                window.location.replace(authUrl401());  
              }, 1000)
        }
    
        if(result)
        {                       
            setDados(result.vehicles)                                       
            setLoaded(true);             
                  
        }
    }

    const advancedsearchLoad = async () => {
        
        const result = await callAPI({service: '/Vehicle/AdvancedsearchLoad', dados: {}})
          
        if(result == null)
        {      
            
          return;
        }

        if(result == 401)
        {
              setCookie("FlashError", "Operação não permitida!", { path: '/' });
            
              setTimeout(()=>{
                window.location.replace(authUrl401());  
              }, 1000)
        }
    
        if(result)
        {                       
            setTipos(result.tipos)                                       
                                               
            setUfs(result.ufs)                                       
                  
        }
        
    }

   

  
    const PreviousPage = () => {
        let pg = ActualPage - 1;

        if (pg < 1)
            pg = 1;

        setActualPage(pg)
        

    }

    const NextPage = () => {
        let pg = ActualPage + 1;

        if (pg > NumberOfPages)
            pg = NumberOfPages;

        setActualPage(pg)

    }

    const handlesearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setsearch(event.target.value);
    };
    



    return (
    <LayoutAuth>
         <PageTitle title="Quantovale - Pesquisa de Veículos"></PageTitle>                     
            <>
            <Container className='mb-2'>
            
            <Row className='main-search mt-3  text-center input-group input-group-lg search-xl'>
                <Col className='col-12'>   
                <div className="input-group input-group-lg search-xl">
                    <input type="text" className="form-control" placeholder="Pesquisar Veículos" aria-label="Pesquisar Veículos" aria-describedby="searchSolicitacao"
                    value={search}
                    onChange={handlesearchTermChange}  />
                    <button className="btn btn-secondary" onClick={(e) => {e.preventDefault();getDados()}} type="button" id="searchSolicitacao"><FontAwesomeIcon className="far" icon={faSearch}/></button>
                </div>   
                </Col> 
                <Col className='col-12 text-end'><a href='#' onClick={(e) => {e.preventDefault();setShowModalAdvanced(true);}}>Busca Avançada</a></Col>               
            </Row>  

            {loaded && <>
            <div className="tab-content" id="myTabContent">               
                    <div className="table-responsive">
                        <Table className='table table-striped' >
                            <thead>
                                <tr>
                                    <th>Foto</th>  
                                    <th>Veículo</th>                                           
                                    <th>Origem</th>    
                                    <th>Cidade/UF</th>                                    
                                    <th>Valor</th>                                    
                                </tr>
                            </thead>
                            <tbody>
                            <>
                            {!dados.length && <><span className='nenhum-resultado'>Nenhum resultado encontrado</span></>}
                            {dados.length && dados.map((data: any, key:number) => {

                                    const top = ((ActualPage * LimitPerPage)-LimitPerPage);
                                    const bottom = top + LimitPerPage;


                                    if(key >= bottom) 
                                       return(<></>)

                                    if(key < top)
                                        return(<></>)

                                return (                 
                                    <tr  className="table-item" >
                                        <td>
                                        <><Zoom  zoomMargin={115}><img height="65" src={data.photo} alt={data.model} referrerPolicy='no-referrer'/></Zoom></>                                                               
                                        </td>                                          
                                        <td onClick={() => {setVehicleId(data.id);setShowModalDetail(true)}}>
                                        <>{data.description}</>                 
                                        </td>                                          
                                        <td onClick={() => {setVehicleId(data.id);setShowModalDetail(true)}}>
                                        <>{data.sources.nome}</>                 
                                        </td>                                                            
                                        <td onClick={() => {setVehicleId(data.id);setShowModalDetail(true)}}>
                                        <>{data.city+'/'+data.uf}</>                 
                                        </td>                                                                                
                                        <td onClick={() => {setVehicleId(data.id);setShowModalDetail(true)}}>
                                        <><NumberFormat fixedDecimalScale={true} decimalScale={2} value={data.price} displayType={'text'} thousandSeparator={"."}  decimalSeparator={","} prefix={"R$ "} /></>                 
                                        </td>                                                            
                                    </tr>
                                )})}  
                                    </>
                            </tbody>
                        </Table>
                    </div>
 
            </div>
        
            {Pagination && <div className="tab-pane " id="concluido" role="tabpanel" aria-labelledby="concluido-tab">
                <div className="pagination-container mb-3">
                    <>
                    <ul className="pagination">
                        <li><a onClick={()=> PreviousPage()}><FontAwesomeIcon className="far" icon={faChevronLeft}/></a></li>
                        {Pages && Pages.map((page: string, index:number) => {
                            return(<><li ><a className={ActualPage == (index+1)?'active':''} onClick={()=> setActualPage(parseInt(page))} >{page}</a></li></>)

                        })}
                        <li><a onClick={()=> NextPage()}><FontAwesomeIcon className="far" icon={faChevronRight}/></a></li>
                    </ul>
                    </>
                </div>
            </div>}
            </>}

                                    

            </Container>          

            <ModalGeneric noFooter={true} icon={faSearch} Size={"lg"} Title={"Busca Avançada"} active={ShowModalAdvanced} handleClose={()=>{setShowModalAdvanced(false); }}>
                <FormBuscaAvancada Dados={dados} Ufs={Ufs} searchAdvanced={searchAdvanced} handleClose={()=>{setShowModalAdvanced(false);}}/>
            </ModalGeneric>

            <ModalGeneric noFooter={true} icon={faSearch} Size={"xl"} Title={"Detalhes do Veículo"} active={ShowModalDetail} handleClose={()=>{setShowModalDetail(false); }}>
                <FormVehicleDetail VehicleId={vehicleId} handleClose={()=>{setShowModalDetail(false);}}/>
            </ModalGeneric>
            </>   
    </LayoutAuth>);
}
