import axios from 'axios';


import { sessionGet } from './session';
import { errorMessage } from './message';

type PcallAPI = {
  service: string;
  dados: object;
};

let PROCESSING = false;

const callAPI = async (fn: PcallAPI) => {
  try {
    if (PROCESSING) return;
    PROCESSING = true;

    const _session = sessionGet();
    let header: { [key: string]: string } = {           
        'Accept': 'application/json',
        'Content-Type': 'application/json',  
        'Access-Control-Allow-Origin': import.meta.env.VITE_HOSTNAME_DEFAULT,
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers':'Origin,Content-Type,Accept',    
        'Access-Control-Allow-Credentials': 'true'
      };

      /*
      'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers':'Origin,Content-Type,Accept',    
        'Access-Control-Allow-Credentials': 'true',
        */


    if (_session) {
      header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',       
        'Access-Control-Allow-Origin': import.meta.env.VITE_HOSTNAME_DEFAULT,
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers':'Origin,Content-Type,Accept',    
        'Access-Control-Allow-Credentials': 'true',         
        Authorization: `Bearer ${_session.token}`,        
        
        
      };
    }

    const response = await fetch(import.meta.env.VITE_API_QuantoVale+fn.service, 
                            {method: 'POST', 
                              mode: 'cors',
                             headers: header,
                             credentials: "include",
                            body: JSON.stringify(fn.dados)})

    PROCESSING = false;
    if(!response.ok)
    {
      return null;
    }
    return await response.json();
    /*

    const result = await axios({
      url: fn.service, 
      baseURL: import.meta.env.VITE_API_QuantoVale,
      method:'POST',
      data: fn.dados, 
      headers: header      
    }
    )
    .then((response) => {
      PROCESSING = false;
      
      return response.data;
      
      
    }).catch((err) => {
      PROCESSING = false;

      let msg = "Erro geral no sistema"
      
      if(err.response)
        if(err.response.data)
          if(err.response.data.message)
            msg = err.response.data.message
      if(err.response)
        if(err.response.code == 401)
        {
          msg = "Problemas de Acesso"  
        }
        
      if(err.response)
        if(err.response.code == 500)
          errorMessage(msg);
   
      if(err.response)
        return err.response.code == 401?401:null;

      return null
      
    });
    return result*/
  }catch(error)
  {
    console.log(error)
    errorMessage("Problemas de comuinicação com o servidor!");
    return null;
  }

  
};

export default callAPI;