import React, {useState, useEffect} from 'react';


import callAPI from '../../services/callAPI';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';

import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

type Props = {    
     
    VehicleId: number;
   
    handleClose: () => void; 
   
    
}

export function FormVehicleMarket({VehicleId, handleClose}:Props) 
{
   
    
    const [Name, setName] = useState('');
    const [Source, setSource] = useState('');
    const [SourceUrl, setSourceUrl] = useState('');
    const [Brand, setBrand] = useState('');
    const [Model, setModel] = useState('');
    const [Description, setDescription] = useState('');
    const [Year, setYear] = useState(0);
    const [YearModel, setYearModel] = useState(0);
    const [City, setCity] = useState('');
    const [UF, setUF] = useState('');
    const [VehicleTransmission, setVehicleTransmission] = useState('');
    const [FuelType, setFuelType] = useState('');
    const [MileageFromOdometer, setMileageFromOdometer] = useState(0);
    const [Price, setPrice] = useState(0);
    const [Photo, setPhoto] = useState('');
    const [PhotoMain, setPhotoMain] = useState('');
    const [Tipo, setTipo] = useState('');
    const [Url, setUrl] = useState('');
    const [Color, setColor] = useState('');
    const [Bodywork, setBodywork] = useState('');
    const [Plate, setPlate] = useState('');
    const [PaymentConditions, setPaymentConditions] = useState('');
    const [Acessories, setAcessories] = useState('');
    const [Images, setImages] = useState('');
    const [ImagesArray, setImagesArray] = useState<string[]>([]);

    const [LoadedImages, setLoadedImages] = useState(false);
    const [Loaded, setLoaded] = useState(false);

  
    //FIPE
    const [FipeMarca, setFipeMarca] = useState('');
    const [FipeModelo, setFipeModelo] = useState('');
    const [FipeCodigoFipe, setFipeCodigoFipe] = useState('');
    const [FipeMesReferencia, setFipeMesReferencia] = useState('');
    const [FipeValor, setFipeValor] = useState(0);

    //MARKET

    const [dadosCidadeEstado_cidade, setDadosCidadeEstado_cidade] = useState('');
    const [dadosCidadeEstado_uf, setDadosCidadeEstado_uf] = useState('');
    const [dadosCidadeEstado_valorMedio, setDadosCidadeEstado_valorMedio] = useState(0);
    const [dadosCidadeEstado_total, setDadosCidadeEstado_total] = useState(0);
        
    const [dadosEstado_uf, setDadosEstado_uf] = useState('');
    const [dadosEstado_valorMedio, setDadosEstado_valorMedio] = useState(0);
    const [dadosEstado_total, setDadosEstado_total] = useState(0);
    
    const [dadosBR_valorMedio, setDadosBR_valorMedio] = useState(0);
    const [dadosBR_total, setDadosBR_total] = useState(0);

    useEffect(() => {
        if(Images)
        {        
            const arrayImagens = Images.split('|');
            setImagesArray(arrayImagens);
            
            
            
        }
      }, [Images]);

    useEffect(() => {
        if(ImagesArray.length > 0)
        {
            console.log('ImagesArray',ImagesArray)
            setLoadedImages(true)
            
            
        }


    }, ImagesArray)

    useEffect( () => { 

        if(VehicleId)
            getVehicle()

    }, [VehicleId]);
    

    const getVehicle = async () => {       
        
                
        const result = await callAPI({service: '/Vehicle/getMarket', dados: {Id: VehicleId}})        
        if(result == null)
          return;
        console.log(result)
        if(result.success)
        {            
            
            
            setName(result.vehicle.name);
            setSource(result.vehicle.sources.nome);
            setSourceUrl(result.vehicle.sources.url);
            setBrand(result.vehicle.brand);
            setModel(result.vehicle.model);
            setDescription(result.vehicle.description);
            setYear(result.vehicle.year);
            setYearModel(result.vehicle.yearModel);
            setCity(result.vehicle.city);
            setUF(result.vehicle.uf);
            setVehicleTransmission(result.vehicle.vehicleTransmission);
            setFuelType(result.vehicle.fuelType);
            setMileageFromOdometer(result.vehicle.mileageFromOdometer);
            setPrice(result.vehicle.price);
            setPhoto(result.vehicle.photo);
            setPhotoMain(result.vehicle.photo);
            setTipo(result.vehicle.tipo);
            setUrl(result.vehicle.url);
            setColor(result.vehicle.color);
            setBodywork(result.vehicle.bodywork);
            setPlate(result.vehicle.plate);
            setPaymentConditions(result.vehicle.PaymentConditions);
            setAcessories(result.vehicle.acessories);            
            setImages(result.vehicle.images);
            

        
            //FIPE
            if(result.vehicle.fipeVeiculoValor)
            {
                setFipeMarca(result.vehicle.fipeVeiculoValor.marca);
                setFipeModelo(result.vehicle.fipeVeiculoValor.modelo);
                setFipeCodigoFipe(result.vehicle.fipeVeiculoValor.codigoFipe);
                setFipeMesReferencia(result.vehicle.fipeVeiculoValor.mesReferencia);
                setFipeValor(result.vehicle.fipeVeiculoValor.valor); 
            }

            //dados
            if(result.dadosCidadeEstado)
            {
                setDadosCidadeEstado_cidade(result.dadosCidadeEstado.cidade)
                setDadosCidadeEstado_uf(result.dadosCidadeEstado.uf)
                setDadosCidadeEstado_valorMedio(result.dadosCidadeEstado.valorMedio)
                setDadosCidadeEstado_total(result.dadosCidadeEstado.total)
            }

            if(result.dadosEstado)
            {                
                setDadosEstado_uf(result.dadosEstado.uf)
                setDadosEstado_valorMedio(result.dadosEstado.valorMedio)
                setDadosEstado_total(result.dadosEstado.total)
            }
            
            if(result.dadosBrasil)
            {   
                setDadosBR_valorMedio(result.dadosBrasil.valorMedio)
                setDadosBR_total(result.dadosBrasil.total)
            }

            setLoaded(true)

            
        }
        
    }

    if(!Loaded)return(<>aguarde...</>)

    return (<>
            {Name != '' && <>
            <Container className='mt-2'>
                <Row>
                <Col className='col-4'>
                        <Card>
                            <Card.Header>Análise do Mercado - Brasil</Card.Header>
                            <Card.Body>
                                <Table striped bordered hover>
                                    <tbody>
                                        <tr className='detail-tr'>
                                            <td>Valor Médio:</td>
                                            <td>{<NumberFormat fixedDecimalScale={true} decimalScale={2} value={dadosBR_valorMedio} displayType={'text'} thousandSeparator={"."}  decimalSeparator={","} prefix={"R$ "} />}</td>
                                        </tr>
                                        <tr className='detail-tr'>
                                            <td>Total de registros similares:</td>
                                            <td>{dadosBR_total}</td>
                                        </tr>                                      
                                    </tbody>
                                </Table>                                
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='col-4'>
                        <Card>
                            <Card.Header>Análise do Mercado - {dadosEstado_uf}</Card.Header>
                            <Card.Body>
                                <Table striped bordered hover>
                                    <tbody>
                                        <tr className='detail-tr'>
                                            <td>Valor Médio:</td>
                                            <td>{<NumberFormat fixedDecimalScale={true} decimalScale={2} value={dadosEstado_valorMedio} displayType={'text'} thousandSeparator={"."}  decimalSeparator={","} prefix={"R$ "} />}</td>
                                        </tr>
                                        <tr className='detail-tr'>
                                            <td>Total de registros similares:</td>
                                            <td>{dadosEstado_total}</td>
                                        </tr>                                      
                                    </tbody>
                                </Table>                                
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='col-4'>
                        <Card>
                            <Card.Header>Análise do Mercado - {dadosCidadeEstado_cidade}/{dadosCidadeEstado_uf}</Card.Header>
                            <Card.Body>
                                <Table striped bordered hover>
                                    <tbody>                                        
                                        <tr className='detail-tr'>
                                            <td>Valor Médio:</td>
                                            <td>{<NumberFormat fixedDecimalScale={true} decimalScale={2} value={dadosCidadeEstado_valorMedio} displayType={'text'} thousandSeparator={"."}  decimalSeparator={","} prefix={"R$ "} />}</td>
                                        </tr>
                                        <tr className='detail-tr'>
                                            <td>Total de registros similares:</td>
                                            <td>{dadosCidadeEstado_total}</td>
                                        </tr>                                      
                                    </tbody>
                                </Table>                                
                            </Card.Body>
                        </Card>
                    </Col>
                   
                </Row>
                <Row>  
                
                             
                    <Col className='col-12'>
                        <Card>
                            <Card.Header className='text-end detail-number'>{<NumberFormat fixedDecimalScale={true} decimalScale={2} value={Price} displayType={'text'} thousandSeparator={"."}  decimalSeparator={","} prefix={"R$ "} />}</Card.Header>
                            <Card.Body>
                                <Table striped bordered hover>
                                    <tbody>
                                        <tr className='detail-tr'>
                                            <td>Vendido por:</td>
                                            <td>{<a href={SourceUrl}>{Source}</a>}</td>
                                        </tr>
                                        <tr className='detail-tr'>
                                            <td>Descrição:</td>
                                            <td>{Description}<br/>{PaymentConditions}</td>
                                        </tr>
                                        <tr className='detail-tr'>
                                            <td>Ano/Modelo:</td>
                                            <td>{Year}/{YearModel}</td>
                                        </tr>
                                        <tr className='detail-tr'>
                                            <td>Transmissão:</td>
                                            <td>{VehicleTransmission}</td>                                            
                                        </tr>
                                        <tr className='detail-tr'>
                                            <td>KM:</td>
                                            <td>{<NumberFormat fixedDecimalScale={false} value={MileageFromOdometer} displayType={'text'} thousandSeparator={"."}  decimalSeparator={","} prefix={""} />}</td>                                            
                                        </tr>
                                        <tr className='detail-tr'>
                                            <td>Combustível:</td>
                                            <td>{FuelType}</td>                                            
                                        </tr>
                                        <tr className='detail-tr'>
                                            <td>Cidade/UF:</td>
                                            <td>{City+'/'+UF}</td>                                            
                                        </tr>
                                        {Color && <>
                                            <tr className='detail-tr'>
                                                <td>Cor:</td>
                                                <td>{Color}</td>                                            
                                            </tr>
                                        </>}
                                        {Bodywork && <>
                                            <tr className='detail-tr'>
                                                <td>Carroceria:</td>
                                                <td>{Bodywork}</td>                                            
                                            </tr>
                                        </>}
                                        {Plate && <>
                                            <tr className='detail-tr'>
                                                <td>Placa:</td>
                                                <td>{Plate}</td>                                            
                                            </tr>
                                        </>}
                                        {Acessories && <>
                                            <tr className='detail-tr'>
                                                <td>Acessórios:</td>
                                                <td>{Acessories}</td>
                                            </tr>
                                        </>}
                                    </tbody>
                                </Table>                                
                            </Card.Body>
                        </Card>
                    </Col>
                    {FipeCodigoFipe != '' &&<>                    
                        <Col className='col-12'>
                            <Card>
                                <Card.Header>Dados FIPE</Card.Header>
                                <Card.Body className='text-center'>
                                    <Table striped bordered hover>
                                        <tbody>
                                            <tr className='detail-tr'>
                                                <td>Código FIPE:</td>
                                                <td>{FipeCodigoFipe}</td>                                            
                                            </tr>
                                            <tr className='detail-tr'>
                                                <td>Marca/Modelo:</td>
                                                <td>{FipeMarca+'/'+FipeModelo}</td>                                            
                                            </tr>
                                            <tr>
                                                <td>Mês Referência:</td>
                                                <td>{FipeMesReferencia}</td>                                                                                        
                                            </tr>
                                            <tr className='detail-tr'>
                                                <td>Valor:</td>
                                                <td><b>{<NumberFormat fixedDecimalScale={true} value={FipeValor} displayType={'text'} thousandSeparator={"."}  decimalSeparator={","} prefix={"R$ "} />}</b></td>                                            
                                            </tr>                                            
                                        </tbody>
                                    </Table>  
                                
                                </Card.Body>                            
                            </Card>
                        </Col>
                        </>}
                </Row>                 
                 <Row className='mt-4'>
                    <Col className='col-12 col-md-12 text-end btn-actions-bottom'>
    
                        <a onClick={(e: any) => { e.preventDefault(); handleClose() }} className="btn btn-width btn-primary text-light">
                        <FontAwesomeIcon icon={faClose} />&nbsp;Fechar
                        </a>                                        
    
                    </Col>
                </Row>                
            </Container>
            </>}   
               
        
          
    </>  
        
 );
}