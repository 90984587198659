import {useState, useEffect} from 'react';

import {Card, Button, Form, Container, Row, Col} from 'react-bootstrap'

import {errorMessage, successMessage, infoMessage, defaultMessage} from '../../services/message';

import callAPI from '../../services/callAPI';

import {useNavigate, useParams}  from 'react-router-dom';


import LayoutAnonymous from '../../components/shared/LayoutAnonymous';
import { authUrlLogin } from '../../services/auth';
import { useCookies } from 'react-cookie';

export function PassChange() 
{ 
  const [cookies, setCookie] = useCookies(['FlashSuccess', 'FlashError']);

  const { token } = useParams(); 
  const navigate = useNavigate();
  
  let [Password, setPassword] = useState('')
  let [ConfirmPassword, setConfirmPassword] = useState('')

  let [TokenOK, setTokenOK] = useState(false)


  useEffect( () => { 
    
    tokenValid(token)
 
  }, []);

  const tokenValid = async(tk: string | undefined) => {
  
    if(tk)
    {
  
      const result = await callAPI({service: '/Auth/forgotToken', dados: {Token: tk}})
  
      if(result == null)
      return;
  
      if(result)
      { 
        setTokenOK(true)
      }
    }
    else
    {
      errorMessage("Token Inválido")

      setTimeout(()=>{
        navigate("/login");
       }, 3000)

    }    
  }
 
  const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();


    if(Password != ConfirmPassword)
    {
      errorMessage("As senhas devem ser iguais!")
      return;
    }
  
      const result = await callAPI({service: '/Auth/resetPass', dados: {Token: token, Password: Password}})
  
      if(result == null)
      {
        
        
        setCookie("FlashError", "Token expirado!", { path: '/' });
        
      
        window.location.replace(authUrlLogin())

        return;
      }
  
      if(result)
      { 
          
        setCookie("FlashSuccess", result.message, { path: '/' });
              
        
        window.location.replace(authUrlLogin())
        
      }
    
  }


  if(!TokenOK) return(<><LayoutAnonymous></LayoutAnonymous></>)


  
  return (<><title>QuantoVale - Definir nova Senha</title>

<LayoutAnonymous>
        <Container className="mt-5 container-login">
          <Row><Col></Col>
          <Col>
            <Card className="mb-3">
              <Card.Body className="card-body">
                <Card.Title>Definir nova Senha</Card.Title>                                             
                <Form onSubmit={(e) => handleSubmit(e)}>                             
                <Form.Group className="mt-3 mb-1">
                <Form.Label className='text-left'>Nova Senha</Form.Label>
                <Form.Control              
                type="password"
                placeholder="Nova Senha"                
                required
                value={Password}
                onChange={e => setPassword( e.target.value )}
              />
                </Form.Group>
                <Form.Group className="mb-1">
                <Form.Label className='text-left'>Repitir Nova Senha</Form.Label>
                <Form.Control              
                type="password"
                placeholder="Confirme Nova Senha"                
                required
                value={ConfirmPassword}
                onChange={e => setConfirmPassword( e.target.value )}
              />
                </Form.Group>  
                  <Row className="mt-2 d-grid">
                    <Button type="submit"  className="btn btn-primary btn-lg" variant="primary">
                      Enviar
                    </Button>
                  </Row>
                </Form>
              </Card.Body>
            </Card></Col>
          <Col></Col>
          </Row>
      </Container>                   
    </LayoutAnonymous>  
 

</>
  );

}