import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Modal, Button} from 'react-bootstrap'

type Props = {
    active: boolean;
    icon?: IconProp;
    Title: string;
    Size?:"sm" | "lg" | "xl" | undefined;
    Btn1?:string|undefined;
    noFooter?:boolean|false;
    children?:
    | React.ReactChild
    | React.ReactChild[];
    handleClose: () => void;    
}

function ModalGeneric({children, active, Title, Size, Btn1, handleClose, icon, noFooter}:Props) {
    
    return (
       <>
            {active &&    
                    
                <Modal size={Size} show={active} onHide={() => handleClose()}>
                    <Modal.Header closeButton>
                    <Modal.Title>
                        {icon?  <><FontAwesomeIcon icon={icon} /> </> : <></> }<span>{Title}</span>
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-start'>{children}</Modal.Body>
                    {!noFooter && <><Modal.Footer>                     
                    <Button className="btn-width" variant="primary" onClick={()=>handleClose()}>
                    {Btn1==undefined?"Sair":Btn1} 
                    </Button>          
                    </Modal.Footer></>}
                </Modal>
                
            }
       </>
       
    );
}

export default ModalGeneric;