import {useState, useEffect} from 'react';


import {Card, Button, Form, Container, Row, Col} from 'react-bootstrap'

import {errorMessage} from '../../services/message';

import callAPI from '../../services/callAPI';

import {useNavigate}  from 'react-router-dom';

import { useCookies } from 'react-cookie';

import { authUrlForgot, authUrlRedirectAny, authUrlRegister } from '../../services/auth';
import SpinnerQuantoVale from '../../components/shared/Spinner';



export function Login() 
{  
  
  const navigate = useNavigate();
  const [Username, setUsername] = useState('')
  const [Password, setPassword] = useState('')
  const [Remember, setRemember] = useState('0')
  
  const [AuthType] = useState(1)
  
  const [cookies, setCookie] = useCookies(['cookie_login_username', 'cookie_login_password', 'cookie_login_remember', 'temporary_token']);

  const [captchatoken] = useState<string | null>(null);

  const [NeedCaptcha] = useState<boolean>(false);

  const [Loading, setLoading] = useState<boolean>(false);




  useEffect( () => { 
    
    //window.location.replace(authUrlDefault());  
    
      
    if(cookies.cookie_login_remember == '1')
    {
      setUsername(cookies.cookie_login_username);
      setPassword(cookies.cookie_login_password);
      setRemember(cookies.cookie_login_remember);
    }

    
  }, []);
 
  const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if(NeedCaptcha && !captchatoken)
    {
      errorMessage("Você é um robô?")
      return;
    }



    setLoading(true);

    if(Remember == '1')
    {
      setCookie("cookie_login_username", Username, {path:'/'})
      setCookie("cookie_login_password", Password, {path:'/'})
      setCookie("cookie_login_remember", Remember, {path:'/'})
    }

    
    
    login(Username, Password)
  }

  const login = async (username: string, password: string) =>{
    


      const result = await callAPI({service: '/Auth/login', dados: {Username: username, Password: password}})
  
      setLoading(false);
      if(result == null)
      {        
        if(AuthType > 1)
        {
          errorMessage("Para login Facebook ou Google, você precisa se registrar antes")
         
          
        }

        return;
      }
  
      if(result)
      {          
          if(result.error)        
          {
            if(result.error == 1)
            {
              errorMessage("Você precisa fazer um cadastro!");
              setTimeout(()=>{
                navigate("/register");
               }, 3000)
            }
            else
              errorMessage(result.message);
          }
          else
          {

            setCookie("temporary_token", null);

            window.location.replace(authUrlRedirectAny("ping/"+result.user.securitY_TOKEN_HASH));      
          }
      }
    
  }


  return (<><title>QuantoVale - Acesse sua conta</title>
   {Loading && <>
      <Container className='loaderQuantoVale mt-5'>
        <Row>
          <SpinnerQuantoVale active={true}/>
        </Row>
      </Container>
   </>}
    

    {!Loading && <><Card className="mb-3">
      <Card.Body className="card-body">
        <Card.Title>Acesso ao sistema</Card.Title>                                             
        <Form onSubmit={(e) => handleSubmit(e)}>                             
        <Form.Group className="mt-3 mb-2">                                
            <Form.Control 
            type="text"
            placeholder="Email, CPF ou Celular"
            required
            value={Username}
            onChange={e => setUsername( e.target.value )}
          />
          </Form.Group>                          
        <Form.Group className="mb-2">                                
            <Form.Control 
            type="Password"
            placeholder="Senha"
            required
            value={Password}
            onChange={e => setPassword( e.target.value )}
          />
          </Form.Group>  

          <div className="d-flex justify-content-center">
              <Form.Group className="mb-2">                                                                        
                    <Row className='mt-2 mb-2'>
                      <Col className='col-2'><Form.Check 
                      type="checkbox"
                      placeholder="Senha"
                      
                      checked={Remember=='1'?true:false}
                      onChange={e => setRemember( e.target.checked?'1':'0' )}
                    /></Col>
                      <Col className='col-10'>Lembrar meus dados</Col>
                    
                    </Row>
                    
              </Form.Group>  
            </div>     

            <div className="d-grid">            
              <Button type="submit"   disabled={!captchatoken && NeedCaptcha?true:false} className="btn btn-primary btn-lg" variant="primary">
                Acessar
              </Button> 
            </div>
          
        </Form>
    </Card.Body>
</Card>
<div className="text-center">
    <a href={authUrlForgot()} className="btn btn-link">Esqueci minha senha</a><br/>
    <a href={authUrlRegister()} className="btn btn-link">Solicite sua conta?</a>
</div> 

</>}

</>
  );

}