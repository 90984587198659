import {Col, Container, Nav, Navbar, NavDropdown, Row} from 'react-bootstrap'


import { useEffect } from 'react';

import logo from "../../assets/static/img/logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';


function HeaderAuth() {
 
    useEffect(() => {
        
        import ('../../styles/tema_quantovale.css');
        
    }, []);

   
    
    return (<>

        <Container>
            <Navbar className='menuAuth'>
        
                    <Navbar.Brand href="#home">
                    <img src={logo} title="Quanto Vale" className="logo-site"/>
                    </Navbar.Brand>            
                    <Navbar.Toggle aria-controls="basic-navbar-nav" ><FontAwesomeIcon className='icon-menu' icon={faBars}/></Navbar.Toggle>
                    

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">                        
                        <NavDropdown title="" id="basic-nav-dropdown" className='dropdown-menu-right'>
                            <NavDropdown.Item href="/profile">&nbsp;Meus Dados</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item href="/logout">&nbsp;Sair</NavDropdown.Item>
                        </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>            
                    
            </Navbar>
        
        </Container>
    
    </>    
    );
}

export default HeaderAuth;