import { toast, ToastOptions } from 'react-toastify';

import { confirm } from "react-confirm-box";
import { customerValueSet, customerValueSetExpiry } from './customer';
import { useCookies } from 'react-cookie';

const options = <ToastOptions>{
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}


const successMessage = (msg:string) =>
{    
  toast.success(msg, options);
}

const errorMessage = (msg:string) =>
{    
  toast.error(msg, options);
}

const infoMessage = (msg:string) =>
{    
  toast.info(msg, options);
}

const warningMessage = (msg:string) =>
{    
  toast.warning(msg, options);
}
const defaultMessage = (msg:string) =>
{    
  toast(msg, options);
}



const confirmMessage = async(msg:string) =>{

  const conf = await confirm(msg)

  return conf  
}

export {errorMessage, successMessage, infoMessage, defaultMessage, warningMessage, confirmMessage };