import {Container, Row, Col} from 'react-bootstrap'

import logo from "../../assets/static/img/logo.png"

function Footer() {
    return (        
                  
            <footer>
                <Container>                   
                    <Row>
                        <Col className="col-md-6 col-12"><span className="footer-left"><img src={logo} className="logo-footer" alt="Quanto Vale" title="Quanto Vale"/></span></Col>
                        <Col className="col-md-6 col-12"><span className="footer-right">© 2025 - QuantoVale - Todos os direitos reservados. Desenvolvido por <a href="https://www.gelicprime.com.br" target="_blank">Gelic Tecnologia.</a></span></Col>
                    </Row>
                </Container>            
            </footer>
        
         
    );
}

export default Footer;