import {useState, useEffect, useCallback, useRef} from 'react';



import {errorMessage, successMessage, infoMessage, defaultMessage} from '../../services/message';

import callAPI from '../../services/callAPI';

import {useNavigate, useParams}  from 'react-router-dom';
import getImage from '../../services/getImage';


export function Logotipo() 
{  


  const navigate = useNavigate();

  
  let [Image, setImage] = useState("/static/images/logo_sistema.png");

  
  useEffect( () => { 
    
  }, []);


  
  
  return <img src={Image} />;

}