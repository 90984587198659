import {useState, useEffect} from 'react';

import {Card, Button, Form, Container, Row, Col} from 'react-bootstrap'

import {errorMessage, successMessage, infoMessage, defaultMessage} from '../../services/message';

import callAPI from '../../services/callAPI';

import {useNavigate, useParams}  from 'react-router-dom';


import LayoutAnonymous from '../../components/shared/LayoutAnonymous';
import { authSubdominio, authUrlLogin } from '../../services/auth';
import { useCookies } from 'react-cookie';

export function ConfirmRegister() 
{ 
  const [cookies, setCookie] = useCookies(['FlashSuccess', 'FlashError']);

  const { token } = useParams(); 
  const navigate = useNavigate();

  let [Confirm, setConfirm] = useState(false)
 


  useEffect( () => { 
    
    if(token)
      Valid(token)
 
  }, []);

  const Valid = async(tk: string | undefined) => {
      if(Confirm)
        return
  
      const result = await callAPI({service: '/Auth/confirmRegister', dados: {Token: tk}})
  
      if(result == null)
      {  
        return;
      }
  
      if(result)
      { 

        setConfirm(true);

      
      }
  
  }



  
  return (<><title>QuantoVale - Confirmação</title>

        <LayoutAnonymous >   
        <>
        {Confirm && <>
          <Container className="mt-5 container-confirm align-content-center">
          <div className="mt-3 d-grid">
            <Row className='mt-1 mb-2'>
              <Col className='col-12'>
                  <div className="d-flex justify-content-center align-items-center" >
                      <h2>Seu registro está confirmado!</h2>
                  </div>
              </Col>
              <Col className='col-12 mt-5'><a href={authUrlLogin()}  className="btn btn-primary btn-lg">
                      Acessar
                    </a>
              </Col>
            </Row>            
          </div>
        </Container>  </>}     
        </>            
      </LayoutAnonymous>  
 

</>
  );

}