import { useState, useEffect } from "react";

import LayoutAnonymous from "./LayoutAnonymous"


import { Container, Row, Col, Card } from 'react-bootstrap'


import { customerSet,  customerValueGetExpiry, customerValueSetExpiry} from '../../services/customer'

import callAPI from '../../services/callAPI';


import { useNavigate }  from 'react-router-dom';

import { successMessage } from '../../services/message';
import { faLongArrowRight } from "@fortawesome/free-solid-svg-icons";

import { authSubdominio, authUrlLogin } from "../../services/auth";


import { Fa_Category_Icon } from "../../services/icons_category";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Page404() 
{
    const navigate = useNavigate();

    const [Categories, setCategories] = useState([])
    const [Loaded, setLoaded] = useState(false);


  
    useEffect(() => {
                      
      
        
    }, []);


    return (<><title>QuantoVale - Não Encontrado</title>
    <LayoutAnonymous>  
    <>
      <Container>
        <Row className="text-center">

                <Col className="col-lg-6 col-md-10 offset-md-1 offset-lg-3 mb-1">
                    <Card className="mb-3">
                        <Card.Body>
                            <img src="/static/images/404.jpg" alt="" className="img-fluid" />
                            <h6 className="card-title h5 text-primary">A página que você está buscando não existe, ou não está mais disponível</h6>
                            <p>Caso tenha alguma dúvida, entre em contato com o nosso suporte.</p>
                            <div className="d-grid">
                                <a onClick={(e)=>{navigate("/")}} className="btn btn-light">Voltar</a>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            
        </Row>
      </Container>


   
        </>
  
    </LayoutAnonymous></>);
}