import { useEffect } from "react";


import { Container, Row, Col } from 'react-bootstrap'


import LayoutHome from "../components/shared/LayoutHome";

import banner_01 from "../assets/static/img/banner.jpg"

import icone_01 from "../assets/static/img/icone_02.png"
import icone_02 from "../assets/static/img/icone_03.png"
import icone_03 from "../assets/static/img/growth.png"
import icone_04 from "../assets/static/img/icone_01.png"


export function Home() 
{

  
    useEffect(() => {
                      
      
        
    }, []);

  

      
    return (<><title>QuantoVale</title>
    <LayoutHome>  
    <>
        <Container className="container-banner cta-home">
            <Row className="d-flex align-items-center ">
                <Col className="col-md-6 mt-5">
                    <h1>O mais moderno precificador de veículos do mercado</h1><br/>
                    <h2>Aumente sua margem de negociação!</h2>
                    
                </Col>
                <Col className="col-md-6">
                    <img src={banner_01} className="img-fluid d-block d-sm-none img-mobile" alt=""/>
                </Col>
            </Row>
        </Container>
    
    
        <Container>
           
            <section className="destaques">
                <Row className="low-row d-flex h-100">
                    <Col className="col-lg-3 col-md-6 col-6 low-col destaque">
                        <img src={icone_01} alt="Inteligência Artificial aplicada" className="icon-destaque"/>
                        <h3>Inteligência Artificial aplicada</h3>
                    </Col>
                    <Col className="col-lg-3 col-md-6 col-6 low-col destaque">
                        <img src={icone_02} alt="Atualizado diariamente" className="icon-destaque"/>
                        <h3>Atualizado diariamente</h3>
                    </Col>
                    <Col className="col-lg-3 col-md-6 col-6 low-col destaque">
                        <img src={icone_03} width="64px" alt="Mais informações sobre o mercado" className="icon-destaque"/>
                        <h3>Mais informações sobre o mercado</h3>
                    </Col>
                    <Col className="col-lg-3 col-md-6 col-6 low-col destaque">
                        <img src={icone_04} alt="Relatório gerado com alguns cliques" className="icon-destaque"/>
                        <h3>Relatório gerado com alguns cliques</h3>
                    </Col>
                </Row>
            </section>
        </Container>
    
        <Container>
            <section className="destaques">
                <a className="anchor" id="como-funciona"></a>
                <Row>
                    <Col className="col-md-4">
                        <img src="/static/home/img/img_destaque.jpg" alt="" className="img-fluid img-mobile"/>
                    </Col>
                    <Col className="col-md-8">
                        <h3>Perfeito para revendas</h3>
                        <h2>Melhore a precificação de carros em sua revenda</h2>
                        <p>Através da nossa ferramenta, digitando informações básicas de um carro, como <strong>modelo</strong>, <strong>kilometragem</strong> e <strong>cor</strong>, você receberá em instantes um <strong>relatório completo.</strong></p>
                        <p>Essa análise é realizada através de <strong>inteligência artificial</strong>, utilizando como base um imenso e atualizado banco de dados de revenda de automóveis.</p>
                    </Col>

                </Row>
            </section>
        </Container>
    
   
        </>
  
    </LayoutHome></>);
}