import LayoutAnonymous from "../../components/shared/LayoutAnonymous"

import {Container, Row, Col} from 'react-bootstrap'


import { Login } from './Login'




export function LoginOnly() 
{  
  
  return (
    <LayoutAnonymous >
     
        <Container className=" container-login mb-3">
        <Row className="text-center">
          
          <Col className="col-lg-6 col-md-10 offset-md-1 offset-lg-3 mb-1">
            <Login/>                   
          </Col>
          
        </Row>
       
      </Container>                   
      
    </LayoutAnonymous>  
  );

}