import {Container, Row, Col} from 'react-bootstrap'
import FlashMessageProvider from './FlashMessageProvider';

type Props = {
    title: string;
}

function PageTitle({ title }:Props) {
    return (
        <>
        <title>QuantoVale - {title}</title>
        <div className="container-fluid page-title">
            <Container>
                <Row>
                    <Col className="col-12 d-flex align-self-center">
                        <h2>{title}</h2>
                    </Col>
                </Row>
            </Container>
        </div>
        <FlashMessageProvider/>
        </>
    );
}

export default PageTitle;