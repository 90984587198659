import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App.tsx'

import './styles/custom.css'
import './styles/globals.css'

import './styles/zoom.css'

import { GoogleOAuthProvider } from '@react-oauth/google';

import { CookiesProvider } from 'react-cookie';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
     <GoogleOAuthProvider clientId={`${import.meta.env.VITE_GOOGLE_AUTH_CLIENT_ID}`}>
    <CookiesProvider>
    <App />
    
    </CookiesProvider>
    </GoogleOAuthProvider>
  </StrictMode>,
)
