import {useEffect} from 'react';

import {useNavigate}  from 'react-router-dom';

import { sessionSet } from '../../services/session';

export function Logout() 
{  
  const navigate = useNavigate();
  

  useEffect( () => {
    sessionSet(null);
    navigate("/login");
      
  }, []);


  
  return (<>
  
   </>);

}