
import React, { FC, useEffect, ReactNode  } from "react";

import {Nav,  Container, Row, Col} from 'react-bootstrap'

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/globals.css'
import '../../styles/custom.css'

import 'react-confirm-alert/src/react-confirm-alert.css';


import Footer from './Footer'

import FlashMessageProvider from "./FlashMessageProvider";

import logo from "../../assets/static/img/logo.png"


// define interface to represent component props
interface Props {
  children?: ReactNode;
  
}

const LayoutAnonymous: FC<Props> = ({ children }) => {
  
    
  useEffect(() => {
        
    import ('../../styles/tema_quantovale.css');
    
  }, []);

  






  return (    
    <>
    
    <Container className="container-fluid">
    <Row className="mb-5">
      <Col className="col-12">
        <Nav className="Navbar Navbar-expand-lg clearHeader fixed-top" id="mainNav">    
            <Container className="container-fluid">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="Nav-25">
                        <a className="Navbar-brand js-scroll-trigger" href="/"><img src={logo} title="Quanto Vale" className="logo-site"/></a>                        
                    </div>
                </div>
            </Container>
        </Nav>
      </Col>
    </Row>
  </Container>

    
    <FlashMessageProvider/>    
    <Row><ToastContainer /></Row>
  
    <Row>
      <Col className="col-12 mt-5">
    
      { children }
      </Col>
    </Row>
  
  
    <Footer  />


  </>
);
}

export default LayoutAnonymous;