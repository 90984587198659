import {Container, Row, Col} from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faRadio, faCircleDot, faCheckCircle, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react';
import { errorMessage, successMessage } from '../../services/message';
import { customerValueGet, customerValueGetExpiry, customerValueSet } from '../../services/customer';
import { useCookies } from 'react-cookie';



function FlashMessageProvider() {

    
    const [cookies, setCookie] = useCookies(['FlashSuccess', 'FlashError']);

    const [loaded, setLoaded] = useState(false)
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')

    

    useEffect(() => {

      
        if(!loaded)          
        {
            setLoaded(true);
            if(cookies.FlashError && cookies.FlashError != 'null')
            {
                setError(cookies.FlashError)
                setCookie("FlashError", null, { path: '/' });
            }
            if(cookies.FlashSuccess && cookies.FlashSuccess != 'null')
            {
                setSuccess(cookies.FlashSuccess)
                setCookie("FlashSuccess", null, { path: '/' });
            }
        }   
      
  
    }, []);

    useEffect(() => {
        if(success)
            successMessage(success)
        
     }, [success]);

     useEffect(() => {
        
        if(error)
            errorMessage(error)
        
     }, [error]);


    return (<>
    
    </>    
    );
}

export default FlashMessageProvider;