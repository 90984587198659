import { useState,  useEffect} from 'react';


import {Card,Form, Container, Row, Col, Button} from 'react-bootstrap'

import {errorMessage} from '../../services/message';

import callAPI from '../../services/callAPI';

import {useNavigate}  from 'react-router-dom';


import {authUrlLogin} from '../../services/auth'
import CaptchaGeneric from '../../components/shared/CaptchaGeneric';
import { useCookies } from 'react-cookie';

import { useForm } from 'react-hook-form';
import { withMask , useHookFormMask } from 'use-mask-input';

export function Register() 
{
  const [cookies, setCookie] = useCookies(['FlashSuccess', 'FlashError']);

  

  const navigate = useNavigate();

  const [Email, setEmail] = useState('')
  const [Name, setName] = useState('')
  const [BirthDate, setBirthDate] = useState(new Date("2000-01-02").toISOString().split('T')[0])
  const [Cpf, setCpf] = useState('')
  const [PhoneNumber, setPhoneNumber] = useState('')
  const [MobileNumber, setMobileNumber] = useState('')
  const [Password, setPassword] = useState('')
  const [PasswordRepeat, setPasswordRepeat] = useState('')
  const [Sub, setSub] = useState('')
  const [AuthType] = useState(1)

 
  
  useEffect( () => { 
    
      
  

    
  }, []);

 

  
  

  const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if(Password.length < 8)
      {
        errorMessage("As senha precisa conter no mínimo 8 dígitos!")
        return 
      }

    if(Password != PasswordRepeat && AuthType == 1)
    {
      errorMessage("As duas Senhas devem ser iguais!")
      return 
    }
    
    const result = await callAPI({service: '/Auth/register', dados: { Email: Email, Name: Name, 
                                                                    BirthDate: BirthDate, 
                                                                    Cpf: Cpf, 
                                                                    PhoneNumber: PhoneNumber, 
                                                                    MobileNumber: MobileNumber, 
                                                                    Password: Password, AuthType: AuthType,
                                                                    GoogleSub: Sub,
                                                                    Subdominio: ""
                                                                   }})

    if(result == null)
      return;

    if(result)
    {      
      setCookie("FlashSuccess", result.message, { path: '/' });
      
      setTimeout(()=>{
        navigate("/login");
      }, 1000)
    }
  }



  return (<><title>QuantoVale - Nova Conta</title>
    <Card className="mb-3">
      <Card.Body className="card-body">
        <Card.Title>Nova Conta</Card.Title> 
        <p className="card-text mb-3">Crie uma conta e tenha acesso ao sistema</p>                            
         
    
        <Form
          name="form-dados"
          onSubmit={(e) => handleSubmit(e)}          
        >  
        
        
        <Container className='mt-3 text-start'>
         <Row >
          <Col className='col-12 col-md-12'>
            <Form.Group className="mb-1">
                <Form.Label className='text-start'>Nome<span className="campo-obrigatorio">*</span></Form.Label>
                <Form.Control
                className="input"
                type="text"
                placeholder="Informe aqui o seu Nome Compconsto"
                required
                value={Name}
                onChange={e => setName(e.target.value)}
              />
                </Form.Group>
          </Col>
          </Row>        
          <Row className='mt-1'>
          <Col className='col-12 col-md-6'>
            <Form.Group className="mb-1">
                <Form.Label className='text-start'>Email<span className="campo-obrigatorio">*</span></Form.Label>
                <Form.Control
                type="text"
                placeholder="Informe aqui o seu Email"
                required
                value={Email}
                onChange={e => setEmail(e.target.value)}
              />
                </Form.Group>
          </Col>
          <Col className='col-12 col-md-6'>
            <Form.Group className="mb-1">
                <Form.Label className='text-start'>CPF<span className="campo-obrigatorio">*</span></Form.Label>                
                <Form.Control
                  ref={withMask("999.999.999-99")}  
                  type="text"
                  placeholder="Informe aqui o seu CPF"
                  required
                  value={Cpf}
                  onChange={e => setCpf(e.target.value)}
                />                
                </Form.Group>
          </Col>
          </Row>
         
          <Row className='mt-1'>
          <Col className='col-12 col-md-4'>
            <Form.Group className="mb-1">
                <Form.Label className='text-start'>Data de Nascimento<span className="campo-obrigatorio">*</span></Form.Label>                
                <Form.Control    
                  type="date"
                  placeholder="Data de Nascimento"
                  required                  
                  onChange={(e) => setBirthDate( e.target.value )}
                /> 
                </Form.Group>
          </Col>
          <Col className='col-12 col-md-4'>
            <Form.Group className="mb-1">
                <Form.Label className='text-start'>Telefone</Form.Label>
                <Form.Control
                  ref={withMask("(99)99999-9999")}                
                  type="text"
                  placeholder="Telefone"                                    
                  value={PhoneNumber}
                  onChange={e => setPhoneNumber( e.target.value )}                                  
                  className='form-control'
                />          
                </Form.Group>
          </Col>
          <Col className='col-12 col-md-4'>
            <Form.Group className="mb-1">
                <Form.Label className='text-start'>Celular<span className="campo-obrigatorio">*</span></Form.Label>
                <Form.Control
                  ref={withMask("(99)99999-9999")}                  
                  type="text"
                  placeholder="Celular"
                  required                  
                  value={MobileNumber}
                  onChange={e => setMobileNumber( e.target.value )}                                  
                  className='form-control'
                />                
                </Form.Group>
          </Col>
          {AuthType == 1 && <Col className='col-12 col-md-6'>
            <Form.Group className="mb-1">
                <Form.Label className='text-start'>Senha<span className="campo-obrigatorio">*</span></Form.Label>
                <Form.Control              
                type="password"
                placeholder="Senha"                
                required
                value={Password}
                onChange={e => setPassword( e.target.value )}
              />
                </Form.Group>
          </Col>}
          {AuthType == 1 && <Col className='col-12 col-md-6'>
            <Form.Group className="mb-1">
                <Form.Label className='text-start'>Repetir Senha<span className="campo-obrigatorio">*</span></Form.Label>
                <Form.Control              
                type="password"
                placeholder="Repita a Senha"                
                required
                value={PasswordRepeat}
                onChange={e => setPasswordRepeat( e.target.value )}
              />
                </Form.Group>
          </Col>}
          </Row>
          <Row className='campo-obrigatorio-detail'>
            <Col className='col-12'>
              <p><span className="campo-obrigatorio">*</span> Campos Obrigatórios</p>
            </Col>
          </Row>
          </Container>
          
          <div className="d-grid mt-3">              
              <Button type="submit"    className="btn btn-primary btn-lg" variant="primary">
                Cadastrar-Me
              </Button> 
              
            </div>
        
        </Form>
    </Card.Body>
  </Card>
  <div className="text-center">
  <a href={authUrlLogin()} className="btn btn-link">Já possui uma conta?</a>
  </div> 
  </>
 
  );

}