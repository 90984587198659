import React, {useState, useEffect} from 'react';

import {Button, Form, Row, Col} from 'react-bootstrap'

import callAPI from '../../services/callAPI';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faSearch} from '@fortawesome/free-solid-svg-icons'




type Props = {    
     
    Ufs: string[]; 
    Dados: any []  
   
    searchAdvanced: (marcaId: number, modeloId: number, anosId:number, UF: string, cidade: string) => void; 
   
    handleClose: () => void; 
   
    
}

export function FormBuscaAvancada({Dados, Ufs , handleClose, searchAdvanced}:Props) 
{
   
    const [Search, setSearch] = useState(false);
    const [TipoFipe, setTipoFipe] = useState('');
        

    const [Marcas, setMarcas] = useState([])
    const [Modelos, setModelos] = useState([])
    const [Anos, setAnos] = useState([])
    const [Cidades, setCidades] = useState([])

    const [MarcaId, setMarcaId] = useState(0);
    const [ModeloId, setModeloId] = useState(0);
    const [AnoId, setAnoId] = useState(0);

    const [UF, setUF] = useState('');
    const [Cidade, setCidade] = useState('');

 

    useEffect( () => { 
        if(Search)
        {
            handleClose()
            searchAdvanced(MarcaId, ModeloId, AnoId, UF, Cidade);
        }
        
    }, [Search]);



    useEffect( () => { 

      
        if(TipoFipe != '')
        {
            getMarcas()
        }
        else{
            setMarcas([])
            setModelos([])
            setAnos([])
        }
        
        
    }, [TipoFipe]);

    useEffect( () => { 
        setModelos([])
        setAnos([])
        if(MarcaId > 0)
        {
            getModelos()
        }
        
        
                
        
    }, [MarcaId]);

    useEffect( () => {         
        setAnos([])
        if(ModeloId > 0)
        {
            getAnos()
        }
        
        
    }, [ModeloId]);

    useEffect( () => { 
        
        if(UF != '')
        {
            getCidades()
        }
        else
            setCidades([])

    }, [UF]);
    

    const getMarcas = async () => {       
        
        
        if(TipoFipe == '')return;
                
        const result = await callAPI({service: '/Vehicle/getMarcas', dados: {PS1: TipoFipe}})        
        if(result == null)
          return;
    
        if(result.success)
        {            
            console.log(result)
            setMarcas(result.marcas)            

            
        }
        
    }

    const getModelos = async () => {
            
        const result = await callAPI({service: '/Vehicle/getModelos', dados: {PS1: TipoFipe, PI1: MarcaId }})
        if(result == null)
          return;
    
        if(result.success)
        {            
            setModelos(result.modelos)
        }
    }


    const getAnos = async () => {
            
        const result = await callAPI({service: '/Vehicle/getAnos', dados: {PS1: TipoFipe, PI1: MarcaId, PI2: ModeloId }})
        if(result == null)
          return;
    
        if(result.success)
        {            
            setAnos(result.anos)
        }
    }

    const getCidades = async () => {
            
        const result = await callAPI({service: '/Vehicle/getCidades', dados: {PS1: UF }})
        if(result == null)
          return;
    
        if(result.success)
        {            
            setCidades(result.cidades)
        }
    }

   
   
        



    return (<>
    
            <Form
                name="form-dados"
                onSubmit={(e:any) => setSearch(true)}
            >   
             <Row className='mt-3'>  
                <Col className='col-2'>
                    <Form.Group className="mb-1">
                        <Form.Label className='text-left'>Tipo de Veículo</Form.Label>
                        <Form.Select aria-label="Selecione" value={TipoFipe} onChange={(e) => {e.preventDefault();setTipoFipe(e.target.value)}}>    
                            <option value="">Selecione</option>
                            <option value="cars">Carros</option>
                            <option value="motorcycles">Motos</option>
                            <option value="trucks">Caminhões</option>                        
                        </Form.Select>
                    </Form.Group>
                </Col>            
                <Col className='col-4'>
                    <Form.Group className="mb-1">
                        <Form.Label className='text-left'>Marca</Form.Label>
                        <Form.Select disabled={Marcas.length == 0?true:false} aria-label="Selecione" value={MarcaId} onChange={(e) => {e.preventDefault();setMarcaId(parseInt(e.target.value))}}>    
                        <option value="0">Selecione</option>
                        {Marcas.length > 0 && Marcas.map((data: any) => ( 
                            <>{data &&
                            <option value={data.id}>{data.nome}</option>
                            }</>
                        ))}  
                        </Form.Select>
                    </Form.Group>
                </Col>            
                <Col className='col-6'>
                    <Form.Group className="mb-1">
                        <Form.Label className='text-left'>Modelo</Form.Label>
                        <Form.Select disabled={Modelos.length == 0?true:false} aria-label="Selecione" value={ModeloId} onChange={(e) => {e.preventDefault();setModeloId(parseInt(e.target.value))}}>    
                        <option value="0">Selecione</option>
                        {Modelos.length && Modelos.map((data: any) => (                
                            <>{data &&
                                <option value={data.id}>{data.nome}</option>
                                }</>
                        ))}  
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mt-3'>  
                <Col className='col-3'>
                    <Form.Group className="mb-1">
                        <Form.Label className='text-left'>Ano</Form.Label>
                        <Form.Select disabled={Anos.length == 0?true:false} aria-label="Selecione" value={AnoId} onChange={(e) => {e.preventDefault();setAnoId(parseInt(e.target.value))}}>    
                        <option value="0">Selecione</option>
                        {Anos.length && Anos.map((data: any) => (                
                            <>{data &&
                                <option value={data.id}>{data.nome}</option>
                                }</>
                        ))}  
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col className='col-3'>
                    <Form.Group className="mb-1">
                        <Form.Label className='text-left'>UF</Form.Label>
                        <Form.Select disabled={Ufs.length == 0?true:false} aria-label="Selecione" value={UF} onChange={(e) => {e.preventDefault();setUF(e.target.value)}}>    
                        <option value="0">Selecione</option>
                        {Ufs.length && Ufs.map((data: any) => (                
                            <option value={data}>{data}</option>
                        ))}  
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col className='col-6'>
                    <Form.Group className="mb-1">
                        <Form.Label className='text-left'>Cidade</Form.Label>
                        <Form.Select disabled={Cidades.length == 0?true:false} aria-label="Selecione" value={Cidade} onChange={(e) => {e.preventDefault();setCidade(e.target.value)}}>    
                        <option value="0">Selecione</option>
                        {Cidades.length && Cidades.map((data: any, key: any) => (                
                            <option value={data}>{data}</option>
                        ))}  
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
           
            
            <Row className='mt-4'>
                <Col className='col-12 col-md-12 text-end btn-actions-bottom'>

                    <a onClick={(e: any) => { e.preventDefault(); handleClose() }} className="btn btn-width btn-primary text-light">
                    <FontAwesomeIcon icon={faClose} />&nbsp;Fechar
                    </a>                                        
                    <Button type="button" className='btn-width' onClick={(e: any) => { e.preventDefault(); setSearch(true) }} variant="secondary">
                        <FontAwesomeIcon icon={faSearch} />&nbsp;Procurar
                    </Button>
                    

                </Col>
            </Row> 
            
        </Form>
        
       
        
          
    </>  
        
 );
}