import {useEffect} from 'react';


import {useNavigate}  from 'react-router-dom';

import { useParams } from 'react-router-dom';

import SpinnerQuantoVale from '../../components/shared/Spinner';
import LayoutAnonymous from '../../components/shared/LayoutAnonymous';

import {Container, Row} from 'react-bootstrap'
import { useCookies } from 'react-cookie';

export function Ping() 
{  
  const {token} = useParams();

  const [cookies, setCookie] = useCookies(['temporary_token']);
 
  const navigate = useNavigate();
  

  useEffect( () => {  
      if(token)
      {
        setCookie("temporary_token", token);        
        ping()
      }  
  }, []);


  const ping = async () =>{        
      navigate("/signin");
  }

  
  return (<>
  <LayoutAnonymous>
   <Container className='loaderQuantoVale mt-5'>
        <Row>
          <SpinnerQuantoVale active={true}/>
        </Row>
      </Container>
      </LayoutAnonymous>
   </>);

}