import {Modal, Container, Row, Col} from 'react-bootstrap'

import ReCAPTCHA from "react-google-recaptcha-enterprise";

type Props = {
    onChange: (value: any) => void;
}

function CaptchaGeneric({onChange}:Props) {
    
    return (
       <>
           <ReCAPTCHA
                sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA}
                onChange={onChange}
            />     
       </>
       
    );
}

export default CaptchaGeneric;