
import crypto from 'crypto-js';

const authUrlAdmin = () =>
{      
  
  return  import.meta.env.VITE_AUTH+"/admin";
}

const authUrlLogin = () =>
{      
  return  import.meta.env.VITE_AUTH+"/login";
}

const authUrlRegister = () =>
{      

  return  import.meta.env.VITE_AUTH+"/register";
}

const authUrlForgot = () =>
{      
  
  return  import.meta.env.VITE_AUTH+"/forgot";
}

const authUrlAny = (method: string) =>
{      
  return  import.meta.env.VITE_AUTH+"/"+method;
}

const authUrlRedirect = () =>
{

  return  import.meta.env.VITE_SUB_HOSTNAME;
}

const authUrlRedirectAny = (method: string) =>
{

  
  return  import.meta.env.VITE_SUB_HOSTNAME+"/"+method;
}


const authUrlDefault = () =>
{
  return  import.meta.env.VITE_HOSTNAME_DEFAULT;
}

const authUrl401 = () =>{

  if(window.location.href.indexOf("admin") > 0)
  {
    return  import.meta.env.VITE_AUTH+"/admin";
  }
  else{
    
    return window.location.origin;
  }
  
}

const authUrlHomeAdmin = () =>
{
  return  import.meta.env.VITE_HOME_ADMIN;
}

const encrypt156 = (evalue: string) => {
  return crypto.AES.encrypt(evalue,  import.meta.env.VITE_SECRET_KEY).toString();
};

const decrypt156 = (evalue: string) => {
  return crypto.AES.decrypt(evalue,  import.meta.env.VITE_SECRET_KEY).toString();
};

export {authUrlLogin,authUrl401,
   authUrlAdmin, authUrlRegister, authUrlRedirect,
    authUrlForgot, authUrlAny, authUrlRedirectAny,
     authUrlDefault,authUrlHomeAdmin, 
     encrypt156, decrypt156 };