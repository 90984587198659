
import React, { FC, useEffect } from "react";

import {useNavigate}  from 'react-router-dom';

import {Row} from 'react-bootstrap'

import { ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/globals.css'
import '../../styles/custom.css'

import 'react-confirm-alert/src/react-confirm-alert.css';

import Footer from './Footer'
import Header from './Header'

import FlashMessageProvider from "./FlashMessageProvider";



// define interface to represent component props
interface Props {
  children: React.ReactNode;  
}

const LayoutHome: FC<Props> = ({ children}) => {
 
    
    useEffect(() => {
   
       
        
    }, []);





  return (    
    <>
    
    <Header />
    <FlashMessageProvider/>    
    <Row><ToastContainer /></Row>
  
  
    { children }
  
  
    <Footer/>

    
  </>
);
}

export default LayoutHome;