import {BrowserRouter as Router,
        Routes,
        Route} from "react-router-dom";


import { Main } from "./pages/main/Main"
//Cidadao

import { Home } from "./pages/Home"


import { Forgot } from "./pages/auth/Forgot";
import { PassChange } from "./pages/auth/PassChange";

import { LoginOnly } from "./pages/auth/LoginOnly";
import { RegisterOnly } from "./pages/auth/RegisterOnly";
import { LoginRouter } from "./pages/auth/LoginRouter";

import { ConfirmRegister } from "./pages/auth/ConfirmRegister";
import { Ping } from "./pages/auth/Ping";

import { Page404 } from "./components/shared/Page404";
import { Logotipo } from "./pages/auth/Logotipo";
import { Logout } from "./pages/auth/Logout";


export function AppRoutes(){
    return(
        <>
            <Router>
                <Routes>                   
                    <Route path="/pesquisas" element={<Main />}/>
                                        
                    <Route path="/" element={<Home />}/>

                    <Route path="/signin" element={<LoginRouter />}>                    
                            <Route path=":category" element={<LoginRouter />} />                    
                    </Route>
                    
                    <Route path="/login" element={<LoginOnly />}/>

                    <Route path="/logout" element={<Logout />}/>

                    <Route path="/ping" element={<Ping />}>
                        <Route path=":token" element={<Ping />} >
                            <Route path=":category" element={<Ping />} />
                        </Route>
                    </Route>

                    <Route path="/register" element={<RegisterOnly />}>
                        <Route path=":subdominio" element={<RegisterOnly />} />
                    </Route>

                    <Route path="/logotipo" element={<Logotipo />}>
                        <Route path=":id" element={<Logotipo />} />
                    </Route> 

                    <Route path="/forgot" element={<Forgot />}>
                        <Route path=":subdominio" element={<Forgot />} />
                    </Route>   
                    <Route path="/passchange" element={<PassChange />}>
                        <Route path=":token" element={<PassChange />} />
                    </Route>
                    <Route path="/confirm" element={<ConfirmRegister />}>
                        <Route path=":token" element={<ConfirmRegister />} />
                    </Route>
                    
                    <Route path="*" element={<Page404/>} />
                </Routes>
            </Router>

        </>
    )
}
