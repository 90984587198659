import {Container, Col, Nav} from 'react-bootstrap'


import { useEffect } from 'react';

import logo_w from "../../assets/static/img/logo_w.png"
import { Link } from 'react-router-dom';

function Header() {
 
    useEffect(() => {
        
        import ('../../styles/tema_quantovale.css');
        
    }, []);

   

    return (<>
   <Nav className="Navbar Navbar-expand-lg clearHeader fixed-top" id="mainNav">
        <div className="container-fluid">
            <Container>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="Nav-25">
                        <a className="Navbar-brand js-scroll-trigger" href="index.html"><img src={logo_w} title="Quanto Vale" className="logo-site"/></a>
                    </div>

                    <div className="Nav-25 right-align">
                        <div className="social-block">                            
                            <Link className="Nav-item Nav-link btn btn-primary" to="/login">Acessar</Link>
                        </div>
                        <button className="Navbar-toggler Navbar-toggler-right " type="button" data-toggle="collapse" data-target="#NavbarResponsive" aria-controls="NavbarResponsive" aria-expanded="false" aria-label="Toggle Navigation">
                            <i className="fas fa-bars"></i>
                        </button>
                    </div>
                </div>
            </Container>
            <div className="container container-Navigation d-lg-none">
                <Col className="collapse Navbar-collapse" id="NavbarResponsive">                    
                    <a className="Nav-item Nav-link btn btn-light mb-3" asp-controller="Login" asp-action="Index">Login</a>
                </Col>
            </div>
        </div>
    </Nav>
    
    </>    
    );
}

export default Header;